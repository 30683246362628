require("./src/styles/global.css")

exports.onClientEntry = () => {
  // Get the user's language set in the browser
  const userLang = navigator.language || navigator.userLanguage
  const browserLangCode = userLang.split("-")[0] // Extract 'en' from 'en-US'

  // Define the supported languages and their path prefixes
  const languagePathPrefixes = {
    fr: "/fr",
    en: "/en",
    nl: "/",
  }

  // Check the current path
  const currentPath = window.location.pathname
  const pathSegments = currentPath.split("/").filter(Boolean) // Remove any empty strings from path
  const firstPathSegment = pathSegments[0] // Get the first segment of the current path

  // Determine if the first segment is a language code
  const isLanguagePath = Object.keys(languagePathPrefixes).includes(firstPathSegment)

  // Prevent redirection if the current path already contains a valid language prefix
  if (!isLanguagePath && currentPath !== "/") {
    // Determine the correct language prefix if the current path does not contain one
    const newPathPrefix = languagePathPrefixes[browserLangCode] || languagePathPrefixes.en // Fallback to English if language is not supported
    const newPath = newPathPrefix + (newPathPrefix.endsWith("/") ? "" : "/") + pathSegments.join("/")
    window.location.pathname = newPath
  }
  // Handle root path for non-Dutch browsers
  else if (currentPath === "/" && browserLangCode !== "nl") {
    window.location.pathname = languagePathPrefixes.en // Default to English
  }
}

// triggers the event towards GA4
exports.onRouteUpdate = ({ location }) => {
  // Set the language attribute based on the pathname
  const pathname = location.pathname
  const pageLang = pathname.substring(1, 3) || "nl"
  document.documentElement.setAttribute("lang", pageLang)

  setTimeout(() => {
    const dataLayer = window.dataLayer || []
    dataLayer.push({ event: `gatsby-route-change` })
  }, 50)

  // Trigger custom event for VWO
  const routeChangeEvent = new Event("gatsby-route-change")
  window.dispatchEvent(routeChangeEvent)
}
